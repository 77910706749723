import i18n from "@/locale";
import { format as _format, getDay, isValid, parseISO } from "date-fns";
import { enGB as en, ru } from "date-fns/locale";

const DEFAULT_FORMAT = "dd.MM.yyyy";
const LOCALES = { en, ru };

function getOptions() {
  return {
    locale: LOCALES[i18n.locale],
  };
}

const format = (date, f = DEFAULT_FORMAT) => {
  if (!date) return "";
  const _date = typeof date === "string" ? parseISO(date) : date;
  return _format(_date, f, getOptions());
};

const formatIfDate = (date, f = DEFAULT_FORMAT) => {
  if (!date) return "";
  if (typeof date !== "string") return date;
  if (date.length < 10) return date; // fix it

  const parsed = parseISO(date);
  return isValid(parsed) ? format(date, f) : date;
};

function getWeekDay(date) {
  return i18n.t("WeekDay")[date.getDay()];
}

const fGetDay = (date) => getDay(date);

export { fGetDay, format, formatIfDate, getWeekDay };
