<template>
  <div class="viewier-wrapper">
    <div class="viewier-container">
      <div v-for="(file, i) in files" :key="i" class="d-flex mb-7">
        <div
          class="viewer-image d-flex justify-center align-center"
          :style="{ backgroundImage: `url('${getImageSrc(file)}')` }"
          @click="openNewTab(file.url)"
        >
          <span v-if="!isImage(file.name)" class="base--text">{{
            getExtention(file.name)
          }}</span>
        </div>
        <div class="viewier-card">
          <p class="viewer-card-title">
            <a
              class="accent--text"
              :href="getFullUrl(file.url)"
              target="_blank"
            >
              {{ getFileName(file.name) }}
            </a>
            <v-icon class="download-icon" @click="download(file)">
              mdi-download
            </v-icon>
          </p>
          <p class="viewer-card-subtitle">
            {{ getUserName(file.user_id) }}
          </p>
          <p class="viewer-card-subtitle">
            {{ getDate(file.created_at) }}
          </p>
        </div>
      </div>
      <span
        v-if="value && value.length > 2"
        class="show-more-btn"
        @click="showMore = !showMore"
        >{{ collapseBtnText }}</span
      >
    </div>
    <div v-if="!readonly" class="add-file-container">
      <label for="file" class="accent--text" :title="$t('ui_add_attachment')">
        +
      </label>
      <input
        id="file"
        type="file"
        name="file"
        hidden
        multiple
        @change="$emit('onAttachmentAppend', $event.target.files)"
      />
    </div>
  </div>
</template>

<script>
import openNewTab from "@/utils/handlers/openNewTab";
import { mapState } from "vuex";
import getFullApiUrl from "@/utils/formaters/getFullApiUrl";
import formatFileName from "@/utils/formaters/formatFileName";
import { format } from "@/utils/date";
import download from "@/utils/handlers/download";

export default {
  props: {
    value: {
      type: Array,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showMore: false,
    };
  },
  computed: {
    ...mapState("core$common", ["users"]),
    files() {
      if (!this.value) return [];

      const files = this.value.slice().reverse();
      return this.showMore ? files : files.slice(0, 2);
    },
    collapseBtnText() {
      return this.showMore ? this.$t("ui_show_less") : this.$t("ui_show_more");
    },
  },
  methods: {
    onChange(files) {
      this.$emit("input", files);
    },
    getFullUrl(url) {
      return getFullApiUrl(url);
    },
    openNewTab(url) {
      openNewTab(getFullApiUrl(url));
    },
    getFileName(text) {
      return formatFileName(text);
    },
    getUserName(userId) {
      const user = this.users.find((user) => user.id === userId);
      if (!user) return "";

      return user._fullname;
    },
    getDate(date) {
      return format(date, "hh:mm dd.MM.yyyy");
    },
    getExtention(fileName) {
      const suffix = fileName.split(".");
      return suffix[suffix.length - 1];
    },
    isImage(fileName) {
      const extention = this.getExtention(fileName);
      const reImage = /image|jpeg|jpg|svg|png/i;
      const catched = extention.match(reImage);
      return catched && catched.length;
    },
    getImageSrc(file) {
      if (!this.isImage(file.name)) return;

      return getFullApiUrl(file.url);
    },
    download(file) {
      download(getFullApiUrl(file.url), file.name);
    },
  },
};
</script>

<style scoped lang="scss">
.viewier-wrapper {
  position: relative;
  .add-file-container {
    width: 60px;
    max-width: 60px;
    font-size: 1.6rem;
    position: absolute;
    top: -30px;
    right: 0;
    user-select: none;
    label {
      cursor: pointer;
    }
  }
  .viewier-container {
    & > div {
      &:hover {
        .download-icon {
          opacity: 1 !important;
        }
      }
    }
    .show-more-btn {
      user-select: none;
      cursor: pointer;
      line-height: 16px;
      text-decoration-line: underline;
      color: #9f9f9f;
    }
    .viewer-image {
      width: 90px;
      height: 70px;
      border: 0.5px solid #b4b4b4;
      border-radius: 1px;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      cursor: pointer;
      span {
        font-size: 1.2rem;
        color: #7a0a0a63;
        font-weight: 600;
      }
    }
    .viewier-card {
      margin-left: 12px;
      line-height: 16.41px;
      .viewer-card-title {
        font-size: 1rem;
        display: inline-block;
        margin-bottom: 17px;
        text-decoration: none;
        position: relative;
        .download-icon {
          position: absolute;
          top: 0;
          right: -30px;
          color: #b4afaf;
          font-size: 1.3rem;
          cursor: pointer;
          opacity: 0;
          transition: all 0.1s ease-out;
        }
      }
      .viewer-card-subtitle {
        color: #b4b4b4;
        margin-bottom: 4px;
      }
    }
  }
}
</style>
