import "@/styles/main.scss";
import "@mdi/font/css/materialdesignicons.css";
import PortalVue from "portal-vue";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import { VueMaskDirective } from "v-mask";
import Vue from "vue";
import Vuelidate from "vuelidate";
import App from "./App.vue";
import i18n from "./locale";
import vuetify from "./plugins/vuetify/index";
import router from "./router";
import store from "./store";

import registerAppFields from "@/components/fields/scripts/registerAppFields";
import Icon from "@/components/Icon/Icon";
import Modal from "@/components/Modal/Modal";
import Alert from "@/plugins/alert/alert";

Vue.config.productionTip = false;

Vue.use(PortalVue);
Vue.use(Alert);
Vue.use(Vuelidate);

Vue.directive("mask", VueMaskDirective);

Vue.component("Icon", Icon);
Vue.component("Modal", Modal);

registerAppFields(Vue);

// Преобразовываем jwt token в store объект при инициализации
store.commit("core$common/setUser");
store.dispatch("core$system/init");

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
