import jwt_decode from "jwt-decode";

const TOKEN_FIELD = "_t";
// const ENGINE_TOKEN_FIELD = "_et";

const authService = {
  // raida token
  setToken(token) {
    if (!token) return console.error("Token is not passed");
    localStorage.setItem(TOKEN_FIELD, token);
  },
  getToken() {
    return localStorage.getItem(TOKEN_FIELD) || null;
  },
  getTokenData() {
    const token = this.getToken();
    try {
      return token ? jwt_decode(token) : null;
    } catch {
      return null;
    }
  },
  clearToken() {
    localStorage.removeItem(TOKEN_FIELD);
    // localStorage.removeItem(ENGINE_TOKEN_FIELD);
  },
  isTokenExists() {
    return !!this.getToken();
  },
  // storage token
  // setStorageToken(token) {
  //   if (!token) return console.error("Token is not passed");
  //   localStorage.setItem(ENGINE_TOKEN_FIELD, token);
  // },
  // getStorageToken() {
  //   return localStorage.getItem(ENGINE_TOKEN_FIELD) || null;
  // },
};

export const AuthService = authService;
