export default {
  AppText: ["text", "email", "number"],
  AppTextarea: ["textarea", "editor"],
  AppSelect: ["select"],
  AppPhone: ["phone"],
  AppAttachments: ["file"],
  AppFileLoader: ["file_loader"],
  AppFileViewer: ["file_viewer"],
  AppTimePicker: ["time"],
  AppTimeRangePicker: ["timerange"],
  AppDate: ["date"],
  AppDatePicker: ["datepicker"],
  AppDateRange: ["daterange"],
  AppDateTime: ["datetime"],
  AppTimeRange: ["timerange"],
  AppDateTimeRange: ["datetimerange"],
  AppJson: ["json"],
  AppAddress: ["address"],
};
