const domainService = {
  INIT_SUBDOMAIN: "start",

  getHostName() {
    return location.hostname;
  },

  getCurrentSubdomain() {
    const { hostname } = location;

    const subdomain = hostname.split(".")[0];
    return subdomain;
  },

  isInitSubdomain() {
    return this.getCurrentSubdomain() === this.INIT_SUBDOMAIN;
  },

  nodeConnect(host) {
    const { protocol, port } = location;

    let url = `${protocol}//${host}`;
    if (port) {
      url += `:${port}`;
    }
    url += "/lk";

    location.assign(url);
  },

  nodeDisconnect() {
    const { origin } = location;

    let url = origin.replace(this.getCurrentSubdomain(), this.INIT_SUBDOMAIN);
    location.assign(url);
  },
};

export const DomainService = domainService;
