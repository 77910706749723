<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-right="40"
    :disabled="readonly"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        v-bind="$attrs"
        :value="dateFormatted"
        :clearable="!readonly"
        readonly
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker
      :value="value"
      :first-day-of-week="1"
      @input="inputHandler"
      @change="$emit('change', $event)"
      @blur="$emit('blue', $event)"
    ></v-date-picker>
  </v-menu>
</template>

<script>
import { format } from "@/utils/date";

export default {
  props: ["value", "readonly"],
  data() {
    return {
      menu: false,
    };
  },
  computed: {
    dateFormatted() {
      return format(this.value);
    },
  },
  methods: {
    inputHandler(e) {
      this.$emit("input", e);
      this.menu = false;
    },
  },
};
</script>
