export default [
  {
    path: "boards",
    name: "adminBoards",
    component: () =>
      import("@/apps/admin/apps/adminBoards/views/AdminBoards.vue"),
  },
  {
    path: "widgets",
    name: "adminWidgets",
    component: () =>
      import("@/apps/admin/apps/adminBoards/views/AdminWidgets.vue"),
  },
];
