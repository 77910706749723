import store from "@/store";

function formatTitle(title) {
  const prefix = store.state["core$common"]?.node?.settings?.title_prefix || "";
  if (!title) return prefix;
  return prefix ? `${prefix} - ${title.toLowerCase()}` : title;
}

const _getTitle = (to) => {
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);
  document.title = formatTitle(nearestWithTitle?.meta?.title);
};

export const getTitle = _getTitle;
