export default [
  {
    path: "processes",
    name: "adminProcesses",
    component: () =>
      import("@/apps/admin/apps/adminProcesses/views/AdminProcesses.vue"),
  },
  {
    path: "teams",
    name: "adminTeams",
    component: () =>
      import("@/apps/admin/apps/adminProcesses/views/AdminTeams.vue"),
  },
];
