import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";

import { getTitle } from "./middleware/getTitle";
import { authGuard } from "./middleware/guards";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  authGuard(to, from, next);
  getTitle(to);
});

export default router;
