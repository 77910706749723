import { sendMessage } from "./utils";
import Alert from "./Alert.vue";

export default {
  install(Vue) {
    Vue.prototype.$alert = function (message) {
      sendMessage(message);
    };

    Vue.component("Alert", Alert);
  },
};
