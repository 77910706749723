import api from "@/api";
import router from "@/router";

import { AuthService } from "@/services/auth/authService";
import { DomainService } from "@/services/auth/domainService";
import { getUserWithFullname } from "@/utils/formaters/getFullUserName";

const state = {
  user: {},
  users: [],
  node: {},
  teams: [],
  processes: [],
  progress: [],
  boards: [],
  // widgets: [],
  fields: [],
  forms: [],
};

function createFavicon(favicon) {
  let link = document.querySelector("link[rel~='icon']");
  if (!link) {
    link = document.createElement("link");
    link.rel = "icon";
    document.head.appendChild(link);
  }
  link.href = favicon;
}

function initialNodeSetup(node) {
  const favicon = node.settings?.logo?.favicon;
  if (!favicon) return createFavicon("/favicon.ico");

  createFavicon(favicon);
}

const actions = {
  init: async ({ dispatch, state }) => {
    const permitted = await dispatch("checkAccess");
    if (!permitted) return;

    initialNodeSetup(state.node);
    const f0 = dispatch("getUsers");
    const f1 = dispatch("getTeams");
    const f2 = dispatch("getProcesses");
    const f3 = dispatch("getProgresses");
    const f4 = dispatch("getBoards");
    const f5 = dispatch("getFields");
    const f6 = dispatch("getForms");
    // const f7 = dispatch("getWidgets");

    return Promise.all([f0, f1, f2, f3, f4, f5, f6]);
  },

  checkAccess: async ({ dispatch, state }) => {
    // Проверка существования домена
    const data = await dispatch("getNodeByDomain");
    if (data?.response?.status === 401) {
      return false;
    }

    if (!state.node.id) {
      router.push({ name: "NodeNotFound" });
      return false;
    }

    // Проверка связки user -> node
    const nodes = await dispatch("getNodes");
    if (
      !nodes.length ||
      !nodes.some((node) => node.domain === state.node.domain)
    ) {
      router.push({ name: "NodeIsNotBindedWithUser" });
      return false;
    }
    return true;
  },
  login: async ({ commit }, payload) => {
    try {
      const { data } = await api.request(payload);
      AuthService.setToken(data.access_token);
      commit("setUser");
      return data;
    } catch (error) {
      AuthService.clearToken();
      return error;
    }
  },
  getNodeByDomain: async ({ commit }) => {
    try {
      const { data } = await api.request({
        name: "getNodeByDomain",
        pathParam: DomainService.getHostName(),
      });
      commit("setNode", data.data);
      return data.data;
    } catch (error) {
      return error;
    }
  },
  getNodes: async ({ state }) => {
    try {
      const { data } = await api.request({
        name: "getNodes",
        pathParam: state.user.id,
      });
      return data.data;
    } catch (error) {
      return error;
    }
  },

  ////////////////////

  getUsers: async ({ commit, state, dispatch }) => {
    try {
      const { data } = await api.request({
        name: "getUsers",
        pathParam: state.node.id,
      });
      commit("setUsers", data.data);
      await dispatch("core$rights/getRights", null, { root: true });
    } catch (error) {
      console.error(error);
    }
  },

  getTeams: async ({ commit, state }) => {
    try {
      const { data } = await api.request({
        name: "getTeams",
        pathParam: state.node.id,
      });
      commit("setTeams", data.data);
    } catch (error) {
      console.error(error);
    }
  },

  getProcesses: async ({ commit, state }) => {
    try {
      const { data } = await api.request({
        name: "getProcesses",
        pathParam: state.node.id,
      });
      commit("setProcesses", data.data);
    } catch (error) {
      console.error(error);
    }
  },

  getProgresses: async ({ commit, state }) => {
    try {
      const { data } = await api.request({
        name: "getProgresses",
        pathParam: state.node.id,
      });
      commit("setProgress", data.data);
    } catch (error) {
      console.error(error);
    }
  },

  getBoards: async ({ commit, state }) => {
    try {
      const { data } = await api.request({
        name: "getBoards",
        pathParam: state.node.id,
      });
      commit("setBoards", data.data);
    } catch (error) {
      console.error(error);
    }
  },

  getFields: async ({ commit, state }) => {
    try {
      const { data } = await api.request({
        name: "getFields",
        pathParam: state.node.id,
      });
      commit("setFields", data.data);
    } catch (error) {
      console.error(error);
    }
  },

  getForms: async ({ commit, state }) => {
    try {
      const { data } = await api.request({
        name: "getForms",
        pathParam: state.node.id,
      });
      commit("setForms", data.data);
    } catch (error) {
      console.error(error);
    }
  },

  getWidgets: async ({ commit, state }) => {
    try {
      const { data } = await api.request({
        name: "getWidgets",
        pathParam: state.node.id,
      });
      commit("setWidgets", data.data);
    } catch (error) {
      console.error(error);
    }
  },
};

const mutations = {
  setNode: (state, node) => (state.node = node),
  setUser: (state) => {
    const user = AuthService.getTokenData()?.user || {};
    state.user = getUserWithFullname(user);
  },
  setUsers: (state, users) => {
    state.users = users.map((user) => getUserWithFullname(user));
  },
  setTeams: (state, teams) => (state.teams = teams),
  setProcesses: (state, processes) => (state.processes = processes),
  setProgress: (state, progress) => (state.progress = progress),
  setBoards: (state, boards) => (state.boards = boards),
  setFields: (state, fields) => (state.fields = fields),
  setForms: (state, forms) => (state.forms = forms),
  setWidgets: (state, widgets) => (state.widgets = widgets),
};

const getters = {
  getStatusesByProcessKey: (state) => (key) => {
    const process = state.processes.find((process) => process.key === key);
    const progress = state.progress.find(
      (progress) => progress.id === process.progress_id
    );
    return progress.statuses;
  },
  getProcessByKey: (state) => (key) => {
    return state.processes.find((process) => process.key === key);
  },
  getProgressByProcessId: (state) => (id) => {
    return state.progress.find((progress) => progress.id === id);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
