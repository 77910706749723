import Vue from "vue";
import Vuex from "vuex";

import core$common from "./common";
import core$rights from "./rights";
import core$system from "./system";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    core$system,
    core$common,
    core$rights,
  },
});
