<template>
  <div class="alert-wrapper">
    <transition-group name="list-move">
      <v-alert
        v-for="message in messagesReversed"
        :key="message + message.key"
        v-model="message.show"
        :type="message.type"
        dismissible
        border="left"
        elevation="5"
        colored-border
        transition="list"
      >
        {{ message.text }}
      </v-alert>
    </transition-group>
  </div>
</template>

<script>
/**
 * @see {message} объект со свойствами
 * @param {text} текст алерта
 * @param {type} тип, возможные значения ниже
 * @param {key} уникальный ключ для корректного обновления (добавляется программно)
 * @param {show} для привязки v-model (добавляется программно)
 *    @value {success|warning|danger}
 */

import { alertEmitter } from "./alertBus";

const MESSAGE_EXPIRES_SECONDS = 5000;

export default {
  data() {
    return {
      alert: true,
      messages: [],
    };
  },
  computed: {
    messagesReversed() {
      return this.messages.slice().reverse();
    },
  },
  methods: {
    addMessage(message) {
      message = Object.assign({ show: true, key: Math.random() }, message);

      this.messages.push(message);

      setTimeout(() => {
        if (this.messages.length) {
          this.removeMessage(0);
        }
      }, MESSAGE_EXPIRES_SECONDS);
    },
    removeMessage(i) {
      this.messages.splice(i, 1);
    },
  },
  created() {
    alertEmitter.on("showMessage", this.addMessage);
  },
};
</script>

<style lang="scss" scoped>
.alert-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9999;
  padding: 5px 10px 0 0;
}

.list-move-enter-active,
.list-move-leave-active {
  transition: all 0.3s ease-out;
}
.list-move-enter /* .list-move-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateX(30px);
}
.list-move-leave-to {
  opacity: 0;
  transform: translateY(30px);
}
</style>
