export default [
  {
    path: "",
    redirect: { name: "login" },
  },
  {
    path: "login",
    name: "login",
    component: () => import("@/apps/auth/views/Login.vue"),
    meta: { title: "Авторизация" },
  },
  {
    path: "logout",
    name: "logout",
    component: () => import("@/apps/auth/views/Logout.vue"),
  },
  {
    path: "registration",
    name: "registration",
    component: () => import("@/apps/auth/views/Registration.vue"),
    meta: { title: "Регистрация" },
  },
];
