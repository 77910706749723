import { rights } from "@/data/rights";
import { AuthService } from "@/services/auth/authService";
import { DomainService } from "@/services/auth/domainService";
import store from "@/store";

const authGuard = async (to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const needInitRequest =
    !from.name || from.matched.every((record) => !record.meta.requiresAuth);

  if (!requiresAuth) {
    return next();
  }

  if (!AuthService.isTokenExists()) {
    return next({
      name: "login",
      query: { redirect: to.fullPath },
    });
  }

  if (needInitRequest) {
    const permitted = await store.dispatch("core$common/init");
    const hasRight = store.getters["core$rights/hasRight"](rights.IsAuthorized);

    if (permitted && !hasRight) {
      return next({
        name: "NodeIsNotBindedWithUser",
        query: { redirect: to.fullPath },
      });
    } else {
      return next();
    }
  }

  next();
};

const domainGuard = (to, next) => {
  const needsDomain = to.matched.some((record) => record.meta.requiresDomain);

  const isInitSubdomain = DomainService.isInitSubdomain();

  if (isInitSubdomain && needsDomain) {
    next({ name: "nodes" });
  }
};

const adminGuard = function (to, from, next) {
  const allowed = store.getters["core$rights/hasRight"](rights.IsAdmin);
  allowed ? next() : next({ name: "home" });
};

export { adminGuard, authGuard, domainGuard };
