export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    v: {
      type: Object,
      default: () => ({}),
    },
    fieldSettings: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    onInput(e, fieldName) {
      const model = Object.assign({}, this.value);
      model[fieldName] = e;
      this.$emit("input", model);
    },
  },
};
