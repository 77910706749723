const getUserFullName = (user) => {
  if (!user) return "";
  if (!user.fio) return user.user_name;

  const { name, surname, middlename } = user.fio;
  let fullName = `${name} ` || "";
  if (surname) fullName += `${surname} `;
  if (middlename) fullName += middlename;

  return fullName;
};

const getUserWithFullname = (user) => {
  user["_fullname"] = getUserFullName(user);
  return user;
};

export { getUserFullName, getUserWithFullname };
