export default [
  {
    path: "forms",
    name: "adminForms",
    component: () =>
      import("@/apps/admin/apps/adminForms/views/AdminForms.vue"),
  },
  {
    path: "fields",
    name: "adminFields",
    component: () =>
      import("@/apps/admin/apps/adminForms/views/AdminFields.vue"),
  },
];
