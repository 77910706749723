<template>
  <div
    class="modal-wrapper"
    :class="[`position-${position}`, showen ? 'showen' : '']"
    :style="modalStyles"
  >
    <div class="modal-container">
      <slot></slot>
      <Icon
        name="close"
        class="modal-close"
        width="20px"
        height="20px"
        color="#9b9b9b"
        isButton
        @click.native="hide"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    animated: {
      type: Boolean,
      default: true,
    },
    position: {
      type: String,
      default: "center", // right, left, top, bottom, center
    },
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "100%",
    },
  },
  data() {
    return {
      showen: false,
    };
  },
  computed: {
    modalStyles() {
      return {
        width: this.width,
        height: this.height,
      };
    },
  },
  methods: {
    show() {
      this.showen = true;
    },
    hide() {
      this.showen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-wrapper {
  position: absolute;
  z-index: 10;
  background: #fff;
  transition: all 0.2s;
  border-left: 1px solid #f3f2f2;
  &.position-left {
    top: 0;
    bottom: 0;
    left: -1000px;
    opacity: 0;
    &.showen {
      left: 0;
      opacity: 1;
    }
  }
  &.position-right {
    top: 0;
    bottom: 0;
    right: -1000px;
    opacity: 0;
    &.showen {
      right: 0;
      opacity: 1;
    }
  }
  &.position-top {
    left: 0;
    right: 0;
    top: -1000px;
    opacity: 0;
    &.showen {
      top: 0;
      opacity: 1;
    }
  }
  &.position-bottom {
    left: 0;
    right: 0;
    bottom: -1000px;
    opacity: 0;
    &.showen {
      bottom: 0;
      opacity: 1;
    }
  }
  &.position-center {
    left: 0;
    right: 0;
    top: 50%;
    bottom: 0;
    margin: 0 auto;
    transform: translateY(-50%);
    opacity: 0;
    z-index: -1;
    &.showen {
      z-index: 10;
      opacity: 1;
    }
  }

  .modal-container {
    position: relative;
    width: 100%;
    min-height: 100%;
    height: 100%;
    padding: 80px 40px 40px;
    .modal-close {
      position: absolute;
      right: 20px;
      top: 20px;
      color: red;
    }
  }
}
</style>
