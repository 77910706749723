export default {
  // ui
  ui_create_task: "Создать задание",
  ui_add_task: "Добавить задание",
  ui_add: "Добавить",
  ui_actions: "Действия",
  ui_editing: "Редактирование",
  ui_profile: "Профиль",
  ui_login: "Войти",
  ui_back: "Назад",
  ui_save: "Сохранить",
  ui_register: "Зарегистрироваться",
  ui_logout: "Выход",
  ui_nodata: "Отсутствуют данные",
  ui_cancel: "Отменить",
  ui_auth: "Авторизация",
  ui_registration: "Регистрация",
  ui_not_reg_yet: "Еще не зарегистрированы?",
  ui_not_auth_yet: "Войти в систему",
  ui_select_node: "Выберите проект",
  ui_select_process: "Выберите процесс",
  ui_process: "Процесс",
  ui_processes: "Процессы",
  ui_next: "Продолжить",
  ui_route_main: "Вернуться на главную",
  ui_create: "Создать",
  ui_add_attachment: "Добавить вложение",
  ui_attachments: "Вложения",
  ui_show_less: "Свернуть",
  ui_users: "Пользователи",
  ui_statuses: "Статусы",
  ui_statuses_progress: "Группы статусов",
  ui_teams: "Команды",
  ui_forms: "Формы",
  ui_fields: "Поля",
  ui_boards: "Дашборды",
  ui_widgets: "Виджеты",
  ui_plugins: "Расширения",
  ui_plugin_store: "Доступные расширения",
  ui_plugin_configs: "Настройка расширений",
  ui_node_settings: "Настройки узла",
  ui_settings: "Настройки",
  ui_ui: "Интерфейс",
  ui_show_more: "Подробнее",

  $task_key: "Ключ",
  $task_process: "Процесс",
  $task_title: "Заголовок",
  $task_title_placeholder: "Введите заголовок",
  $task_description: "Описание",
  $task_description_placeholder: "Введите описание",
  $task_author: "Автор",
  $task_author_placeholder: "Назначить автора",
  $task_assignee: "Исполнитель",
  $task_assignee_placeholder: "Выберите исполнителя",
  $task_status: "Статус",
  $task_status_placeholder: "Выберите статус",
  $task_attachments: "Файлы",
  $task_attachments_placeholder: "Загрузите файл",
  $task_created: "Дата создания",

  // app errors
  form_not_founded: "Форма не найдена",
  json_not_valid: "Невалидный JSON",
  board_does_not_extsts: "Дашборд не подключен",
  // validator errors
  error_required: "Поле обязательное",
  error_minLength: "Минимальное кол-во символов: {min}",
  error_maxLength: "Максимальное кол-во символов: {max}",
  error_email: "Введите правильный формат почты",
  // request messages
  "users.user_created": "Пользователь успешно создан",
  "users.user_not_created": "Пользователь не создан",
  "users.user_deleted": "Пользователь удален",
  "users.user_not_deleted": "Пользователь не удален",
  "users.user_edited": "Пользователь изменен",
  "users.user_not_edited": "Пользователь не изменен",
  "users.user_already_registered": "Пользователь уже зарегистрирован",
  "users.user_not_found": "Пользователь не найден",
  "users.incorrect_username_or_password": "Неверные пароль или имя",
  "users.credentials_validate_error": "Ошибка аутентификации",
  "users.user_not_authenticated": "Ошибка аутентификации",
  "value_error.email": "Введите корректный адрес почты",
  value_error: "Проверьте правильность введенных данных",
  "tasks.task_created": "Задача успешно создана",
  "tasks.task_edited": "Задача отредактирована",
  "boards.board_edited": "Изменения сохранены",
  "boards.board_not_edited": "Изменения не сохранены",
  "forms.form_edited": "Изменения сохранены",
  "forms.form_not_edited": "Изменения не сохранены",
  "attachments.attachment_deleted": "Вложение удалено",
  "attachments.attachment_not_deleted": "Вложение не удалено",

  page_not_found: "Страница не найдена",
  node_not_found: "Узел не найден",
  user_not_binded: "Доступ запрещен",

  today: "сегодня",
  WeekDay: [
    "воскресенье",
    "понедельник",
    "вторник",
    "среда",
    "четверг",
    "пятница",
    "суббота",
  ],
  noTask: "На {thisDay} {thisMonth} задач нет",
};
