<template>
  <svg
    v-if="name"
    :style="{ width, height, color }"
    :class="isButton ? 'isButton' : ''"
  >
    <use xmlns:xlink="http://www.w3.org/1999/xlink" :xlink:href="getSrc" />
  </svg>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      default: "auto",
    },
    height: {
      type: String,
      default: "auto",
    },
    color: {
      type: String,
      default: "#000",
    },
    isButton: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    getSrc() {
      return require(`../../../public/sprite.svg`) + "#" + this.name;
    },
  },
};
</script>

<style lang="scss" scoped>
svg {
  &.isButton {
    cursor: pointer;
  }
  fill: currentColor;
  & * {
    fill: currentColor;
  }
}
</style>
