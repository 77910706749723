<template>
  <v-text-field
    v-mask="dateMask"
    type="text"
    v-bind="$attrs"
    :value="value"
    :placeholder="getPlaceholder"
    @input="$emit('input', $event)"
    @change="$emit('change', $event)"
    @blur="$emit('blue', $event)"
  ></v-text-field>
</template>

<script>
import { dateMask } from "../../utils/mask";

const DEFAULT_PLACEHOLDER = "00-00-0000";

export default {
  props: ["value", "placeholder"],
  data() {
    return {
      dateMask,
    };
  },
  computed: {
    getPlaceholder() {
      if (!this.placeholder) return DEFAULT_PLACEHOLDER;

      return `${this.placeholder} (${DEFAULT_PLACEHOLDER})`;
    },
  },
};
</script>
