<template>
  <div class="w-100">
    <component
      :is="componentIs"
      v-bind="$attrs"
      :type="type"
      :value="value"
      :errorMessages="errors"
      :v="v"
      error-count="20"
      @change="changeHandler"
      @input="inputHandler"
      @blur="blurHandler"
      @onAttachmentDelete="$emit('onAttachmentDelete', $event)"
      @onAttachmentAppend="$emit('onAttachmentAppend', $event)"
    />
  </div>
</template>

<script>
import formatLocale from "@/utils/formaters/formatLocale";
import fieldTypes from "./data/fieldTypes";

export default {
  props: {
    type: {
      type: String,
      default: "text",
    },
    fieldName: {
      type: String,
      default: "",
    },
    v: {
      type: Object,
      default: undefined,
    },
    value: {
      default: "",
    },
  },
  computed: {
    errors() {
      if (!this.v || !this.v.$error) return [];

      const result = [];
      for (let validatorName in this.v.$params) {
        if (this.v[validatorName] === false) {
          result.push(
            formatLocale(
              `error_${validatorName}`,
              false,
              this.v.$params[validatorName]
            )
          );
        }
      }

      return result;
    },
    componentIs() {
      let componentIs = "";
      Object.entries(fieldTypes).forEach(([key, value]) => {
        if (value.includes(this.type)) componentIs = key;
      });
      return componentIs;
    },
  },
  methods: {
    inputHandler(e) {
      this.$emit("input", e);
    },
    blurHandler() {
      this.$emit("blur", this.fieldName);
    },
    changeHandler() {
      this.$emit("change", this.fieldName);
    },
  },
};
</script>

<style lang="scss" scoped>
.field-wrapper {
  position: relative;
}
</style>
