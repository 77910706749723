<template>
  <v-row>
    <v-col
      v-for="(fieldValue, fieldKey, i) in value"
      v-show="show(fieldKey)"
      :key="fieldKey + i"
      cols="12"
      sm="12"
      :md="fieldSettings[fieldKey].grid || 12"
    >
      <AppFieldCreator
        v-bind="$attrs"
        :label="fieldSettings[fieldKey].title"
        :placeholder="fieldSettings[fieldKey].placeholder"
        :value="value[fieldKey]"
        :readonly="fieldSettings[fieldKey].readonly"
        :type="fieldSettings[fieldKey].type"
        :v="v[fieldKey]"
        :items="fieldSettings[fieldKey].options"
        :itemText="fieldSettings[fieldKey].optionText"
        :itemValue="fieldSettings[fieldKey].optionValue"
        hide-details="auto"
        dense
        @input="onInput($event, fieldKey)"
        @change="$emit('change', $event)"
        @blur="$emit('blur', $event)"
      />
    </v-col>
  </v-row>
</template>

<script>
import appJsonMixin from "@/components/fields/mixins/appJsonMixin";

const HIDDEN_FIELDS = ["lat", "lon"];

export default {
  mixins: [appJsonMixin],
  methods: {
    show(fieldKey) {
      return !HIDDEN_FIELDS.includes(fieldKey);
    },
  },
};
</script>
