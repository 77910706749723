<template>
  <VueEditor
    v-bind="$attrs"
    :value="value"
    :editorToolbar="editorToolbar"
    @blur="onBlur"
    @input="onInput($event)"
  />
</template>

<script>
import { VueEditor } from "vue2-editor";
import editorToolbar from "./data/editorToolbar";

export default {
  components: { VueEditor },
  props: ["value"],
  data() {
    return {
      editorToolbar,
    };
  },
  methods: {
    onBlur() {
      this.$emit("blur");
      this.$emit("change");
    },
    onInput(e) {
      this.$emit("input", e);
    },
  },
};
</script>
