<template>
  <v-text-field
    v-mask="'+7##################'"
    v-bind="$attrs"
    :value="value"
    type="tel"
    @input="$emit('input', $event)"
    @change="$emit('change', $event)"
    @blur="$emit('blue', $event)"
  ></v-text-field>
</template>

<script>
export default {
  props: ["value"],
};
</script>
