import AppFieldCreator from "../AppFieldCreator";

import AppAttachments from "../components/AppAttachments/AppAttachments";
import AppDatePicker from "../components/AppDatePicker/AppDatePicker";
import AppFieldEditor from "../components/AppFieldEditor/AppFieldEditor";
import AppPhone from "../components/AppPhone/AppPhone";
import AppTime from "../components/AppTime/AppTime";
import AppTimeRange from "../components/AppTimeRange/AppTimeRange";
import AppTextarea from "../components/AppTextarea/AppTextarea";
import AppText from "../components/AppText/AppText";
import AppSelect from "../components/AppSelect/AppSelect";
import AppDate from "../components/AppDate/AppDate";
import AppDateRange from "../components/AppDateRange/AppDateRange";
import AppDateTime from "../components/AppDateTime/AppDateTime";
import AppDateTimeRange from "../components/AppDateTimeRange/AppDateTimeRange";
import AppFileLoader from "../components/AppFileLoader/AppFileLoader";
import AppFileViewer from "../components/AppFileViewer/AppFileViewer";
import AppTextBig from "../components/AppTextBig/AppTextBig";
import AppJson from "../components/AppJson/AppJson";
import AppAddress from "../components/AppAddress/AppAddress";

const components = {
  AppFieldCreator,
  AppAttachments,
  AppDatePicker,
  AppFieldEditor,
  AppPhone,
  AppTime,
  AppTimeRange,
  AppTextarea,
  AppText,
  AppSelect,
  AppDate,
  AppDateRange,
  AppDateTime,
  AppDateTimeRange,
  AppFileLoader,
  AppFileViewer,
  AppTextBig,
  AppJson,
  AppAddress,
};

export default (Vue) => {
  for (let componentName in components) {
    Vue.component(componentName, components[componentName]);
  }
};
