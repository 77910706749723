import { guards } from "./guards";

export default [
  {
    path: "t/add",
    name: "TaskAdd",
    component: () => import("@/apps/tasks/views/TaskAdd/TaskAdd.vue"),
    meta: { title: "создание задачи" },
    beforeEnter: guards,
  },
  {
    path: "t/add/:processKey",
    name: "TaskAddDetails",
    component: () =>
      import("@/apps/tasks/views/TaskAdd/TaskAddDetails/TaskAddDetails.vue"),
    meta: { title: "создание задачи" },
    beforeEnter: guards,
  },
  {
    path: "t/:processKey/:taskKey",
    name: "TaskCard",
    component: () => import("@/apps/tasks/views/TaskCard/TaskCard.vue"),
    meta: { title: "карточка задачи" },
  },
];
