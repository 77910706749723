<template>
  <v-text-field
    v-mask="timeRangeMask"
    v-bind="$attrs"
    :value="value"
    :placeholder="getPlaceholder"
    @input="$emit('input', $event)"
    @change="$emit('change', $event)"
    @blur="$emit('blue', $event)"
  ></v-text-field>
</template>

<script>
import { timeRangeMask } from "../../utils/mask";

const DEFAULT_PLACEHOLDER = "00:00-23:59";

export default {
  props: ["value", "placeholder"],
  data() {
    return {
      timeRangeMask,
    };
  },
  computed: {
    getPlaceholder() {
      if (!this.placeholder) return DEFAULT_PLACEHOLDER;

      return `${this.placeholder} (${DEFAULT_PLACEHOLDER})`;
    },
  },
};
</script>
