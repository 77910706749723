import axios from "axios";
import router from "@/router";
import { sendMessage } from "@/plugins/alert/utils";
import VueI18n from "@/locale";

function callAlert(customParams, detail, alertType = "info") {
  if (customParams && customParams.showAlert && detail && detail.length) {
    detail.forEach((item) => {
      sendMessage({
        text: VueI18n.t(item.type),
        type: alertType,
      });
    });
  }
}

axios.interceptors.response.use(
  function (response) {
    const { data, config } = response;

    callAlert(config.customParams, data?.detail, "success");

    return response;
  },
  function (error) {
    const { data, config, status } = error.response;

    callAlert(config.customParams, data?.detail, "warning");

    if (status === 401) {
      router.push({ name: "login" });
    }

    return Promise.reject(error);
  }
);

export default axios;
