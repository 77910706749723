<template>
  <div :id="id" contenteditable :style="{ width, height }">
    {{ value }}
  </div>
</template>

<script>
export default {
  props: {
    value: {
      default: "",
    },
    width: {
      default: "auto",
    },
    height: {
      default: "160px",
    },
  },
  data() {
    return {
      id: "f_" + Date.now(),
    };
  },
  methods: {
    onInput(e) {
      this.$emit("input", e.target.innerText);
    },
  },
  mounted() {
    this._el = document.getElementById(this.id);
    this._el.addEventListener("input", this.onInput);
  },
  beforeDestroy() {
    this._el.removeEventListener("input", this.onInput);
  },
};
</script>

<style lang="scss" scoped>
div {
  border: 1px solid #ababab;
  padding: 10px;
  background: rgb(149 150 153 / 6%);
  border-radius: 4px;
}
</style>
