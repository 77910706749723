import axios from "./interceptor";
import methods from "@/api/methods";
import { AuthService } from "@/services/auth/authService";

export default {
  getHeaders() {
    return {
      headers: {
        Authorization: `Bearer ${AuthService.getToken()}`,
      },
    };
  },

  // формируем корректный запрос
  // функция существует для возможности передачи аргументов в interceptor
  request({ name, pathParam, payload, showAlert = false }) {
    let { path, method, headers } = methods[name](pathParam);
    if (!headers) headers = this.getHeaders();

    const params = {
      ...headers,
      customParams: { showAlert },
    };

    if (method === "get" || method === "delete") {
      return axios[method](path, params);
    } else {
      return axios[method](path, payload, params);
    }
  },
};
