export default [
  {
    path: "plugin-store",
    name: "adminPluginStore",
    component: () =>
      import("@/apps/admin/apps/adminPlugins/views/adminPluginStore.vue"),
  },
  {
    path: "plugin-configs",
    name: "adminPluginConfigs",
    component: () =>
      import("@/apps/admin/apps/adminPlugins/views/adminPluginConfigs.vue"),
  },
];
