<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.ymaps-2-1-79-copyrights-pane {
  display: none !important;
}
em.dot {
  display: inline-block;
  vertical-align: middle;
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

// TEMP

$controlls-width: 100px;
$min-item-width: 256px;
$item-height: 64px;
$transition: all 0.3s ease;

@keyframes opacity {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}

@mixin vertical-center {
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

@mixin self-center {
  @include vertical-center;
  left: 0;
  right: 0;
}

@mixin overlay-fixed {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

@mixin overlay-sticky {
  position: sticky;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

@mixin overlay-absolute {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

@mixin modal-side-right {
  @include overlay-sticky;
  z-index: 20;
  .modal-container {
    @include overlay-sticky;
    left: auto;
    margin-left: auto;
    z-index: 40;
  }
  .modal-content {
    height: 100%;
    position: relative;
    .modal-close-btn {
      position: absolute;
      top: 20px;
      right: 20px;
      width: 28px;
      height: 28px;
      min-width: 28px;
      min-height: 28px;
      color: #d2d2d2;
      cursor: pointer;
    }
  }
}

.widget-calendarfull-wrapper {
  background: #fcfcfc;
  & > .field-datepicker-wrapper {
    display: inline-block;
    width: auto;
    margin-bottom: 28px;
    .field-input-block {
      height: 40px;
      border: none;
      &:hover,
      &:active,
      &:focus,
      &:focus-within {
        border: none;
      }
      .field-input {
        color: #343957;
        font-size: 1.25rem;
        font-weight: 400;
        letter-spacing: 0.2px;
      }
    }
  }
  .calendarfull-container {
    transition: $transition;
    &.loading {
      animation: opacity 1s linear infinite;
    }
  }
  .calendarfull-daily {
    .calendarfull-daily-header {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      border-bottom: 1px solid #343957;
      position: sticky;
      top: 0;
      z-index: 1;
      background: #fcfcfc;
      .calendarfull-daily-arrows {
        display: flex;
        width: $controlls-width;
        min-width: $controlls-width;
        height: 56px;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        svg {
          color: #1f1f1f;
        }
        &:hover:not(.disabled) {
          background: #e9e5fa;
        }
        &:active {
          svg {
            color: #343957;
          }
        }
        &.disabled {
          svg {
            color: #8f8f8f !important;
          }
        }
        &.calendarfull-daily-arrows-left {
          svg {
          }
        }
        &.calendarfull-daily-arrows-right {
          svg {
          }
        }
      }
      .calendarfull-user-wrapper {
        display: flex;
        width: 100%;
        overflow-x: hidden;
        & > div {
          display: flex;
          justify-content: flex-start;
          transition: $transition;
          min-width: 100%;
        }
        .calendarfull-user {
          // min-width: $min-item-width;
          display: flex;
          padding: 0 20px;
          justify-content: flex-start;
          align-items: center;
          border-left: 1px solid #d2d2d2;
          user-select: none;
          // flex-grow: 1;
          &:last-of-type {
            border-right: 1px solid #d2d2d2;
          }
          .v-avatar {
            margin-right: 20px;
            background: #6b51df;
            color: #fff;
          }

          // .avatar-wrapper {
          //   width: 32px;
          //   height: 32px;
          //   min-width: 32px;
          //   min-height: 32px;
          //   margin-right: 20px;
          // }
          .calendarfull-user-container {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            .calendarfull-user-title {
              font-weight: 500;
              color: #1f1f1f;
              margin-bottom: 2px;
              display: inline;
            }
            .calendarfull-user-subtitle {
              font-weight: 400;
              color: #8f8f8f;
              font-size: 0.75rem;
              margin-bottom: 0;
            }
          }
        }
      }
    }
    .calendarfull-daily-inner {
      overflow-x: hidden;
      overflow-y: auto;
      padding-top: 12px;
      position: relative;
      .calendarfull-daily-inner-timeline {
        width: $controlls-width;
        font-weight: 400;
        color: #8f8f8f;
        // user-select: none;
        position: absolute;
        top: 12px;
        &.calendarfull-daily-inner-timeline-left {
          padding-left: 8px;
          left: 0;
          .calendarfull-daily-inner-timeline-item {
            div {
              &:after {
                border-top: 1px solid #d2d2d2;
                width: 30%;
                height: 100%;
                content: "";
                display: block;
                position: absolute;
                top: -1px;
                bottom: 0;
                right: 0;
              }
            }
          }
        }
        &.calendarfull-daily-inner-timeline-right {
          padding-right: 8px;
          right: 0;
          .calendarfull-daily-inner-timeline-item {
            flex-direction: row-reverse;
            div {
              text-align: right;
              &:before {
                border-top: 1px solid #d2d2d2;
                width: 30%;
                height: 100%;
                content: "";
                display: block;
                position: absolute;
                top: -1px;
                bottom: 0;
                left: 0;
              }
            }
          }
        }
        .calendarfull-daily-inner-timeline-item {
          height: $item-height;
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          &:first-of-type {
            div::before {
              top: 0px !important;
            }
            div::after {
              top: 0px !important;
            }
          }
          div {
            position: relative;
            width: 100%;
            height: 100%;
            span {
              display: inline-block;
              transform: translateY(-10px);
            }
          }
        }
      }
      .calendarfull-daily-inner-container {
        width: 100%;
        padding: 0 100px;
        .calendarfull-daily-inner-subcontainer {
          overflow: hidden;
          .calendarfull-daily-inner-trans-container {
            display: flex;
            transition: $transition;
            position: relative;
            .calendarfull-daily-cards {
              .calendarfull-daily-card-wrapper {
                position: absolute;
                top: 0;
                left: 0;
                width: 256px;
                height: 64px;
                padding: 0 4px 0 3px;
                .calendarfull-daily-card {
                  background: #e5e7ef;
                  border-radius: 4px;
                  padding: 0px 12px;
                  border-left: 4px solid #d2d2d2;
                  border-top: 1px solid #ededed;
                  border-bottom: 1px solid #ededed;
                  overflow: auto;
                  width: 100%;
                  height: 100%;
                  -ms-overflow-style: none; /* Internet Explorer 10+ */
                  scrollbar-width: none; /* Firefox */
                  &::-webkit-scrollbar {
                    display: none; /* Safari and Chrome */
                  }
                  &:hover {
                    box-shadow: 0px 10px 15px 0px rgba(202, 202, 202, 0.25);
                    cursor: pointer;
                  }
                  & > * {
                    text-overflow: ellipsis;
                    overflow: hidden;
                    width: 100%;
                  }
                  .calendarfull-daily-card-header {
                    font-weight: 400;
                    font-size: 0.875rem;
                    color: var(--text-70);
                    margin-bottom: 2px;
                  }
                  .calendarfull-daily-card-title {
                    color: #1f1f1f;
                    font-weight: 500;
                    margin-bottom: 4px;
                  }
                  .calendarfull-daily-card-text {
                    font-weight: 400;
                    font-size: 0.875rem;
                    color: #1f1f1f;
                  }
                }
              }
            }
          }
        }
        .calendarfull-daily-inner-x {
          // flex-grow: 1;
          &:last-of-type {
            .calendarfull-daily-inner-y {
              border-right: 1px solid #d2d2d2;
            }
          }
          .calendarfull-daily-inner-y {
            // min-width: $min-item-width;
            height: $item-height;
            border-bottom: 1px solid #d2d2d2;
            border-left: 1px solid #d2d2d2;
            align-items: center;
            display: flex;
            &:first-of-type {
              border-top: 1px solid #d2d2d2;
            }
            &:last-of-type {
              border-bottom: none;
            }
            hr {
              width: 100%;
              opacity: 0.3;
              border: 1px solid #d2d2d2;
            }
          }
        }
      }
    }
  }
}
@mixin widget-card-modal {
  @include modal-side-right;
  .modal-container {
    width: 400px;
    box-shadow: -4px 0px 15px 0px rgba(158, 159, 165, 0.25);
    background: #fff;
    .modal-content {
      padding: 52px 32px 84px 32px;
      .widget-card-inner {
        height: 100%;
        overflow-y: auto;
        .widget-card-title {
          font-weight: 400;
          color: #000;
          font-size: 1.25rem;
          margin-bottom: 12px;
        }
        .widget-card-subtitle {
          font-weight: 400;
          font-size: 0.875rem;
          color: #8f8f8f;
          margin-bottom: 40px;
        }
        .widget-card-status {
          font-weight: 400;
          font-size: 0.875rem;
          color: #1f1f1f;
          margin-bottom: 40px;
          padding: 8px;
        }
      }
      .task-details-btn {
        // @include button-secondary;
        position: absolute;
        bottom: 40px;
        width: 327px;
        margin: auto;
      }
    }
  }
  .field-wrapper {
    margin-bottom: 28px;
    .field-label {
      font-size: 0.75rem;
      font-weight: 400;
      margin-bottom: 4px;
      color: #8f8f8f;
    }
    .field-input,
    .multiselect__single,
    .multiselect__input,
    .multiselect__placeholder,
    .multiselect__tags {
      padding: 0 !important;
    }
    .field-input-block {
      height: 24px;
    }
  }
}

.widget-outer-wrapper {
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  .widget-outer-container {
    width: 100%;
    height: 100%;
  }
  iframe {
    width: 100%;
    height: 100%;
    border-width: 0px;
  }
}
.widget-wrapper {
  width: 100%;
  height: 100%;
}

.widget {
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  .widget-title {
    color: #343957;
    font-weight: 400;
    line-height: 24px;
    font-size: 1.25rem;
    margin-bottom: 28px;
  }
}

.widget-calendarfull-wrapper-base {
  @include widget-card-modal;
}
.widget-card-sidebar-wrapper {
  position: sticky;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
  .v-input__slot {
    &::before,
    &:hover,
    &:focus,
    &:active {
      border-style: none !important;
    }
  }
  .v-text-field__slot {
    label {
      font-size: 14px;
      font-weight: 400;
      color: #8f8f8f;
      margin-bottom: 4px;
    }
    input,
    textarea {
      font-weight: 400;
      font-size: 16px;
      color: #1f1f1f !important;
    }
  }
}

.from-right-enter-active {
  transition: all 0.1s ease-out;
  transform: translateX(0);
}
.from-right-enter-from {
  transform: translateX(500px);
}

.widget-calendarfull-datepicker {
  & + .v-input {
    max-width: 180px;
    .v-input__icon--clear {
      display: none;
    }
    .v-text-field__slot {
      input {
        color: #6b51df;
        font-size: 18px;
      }
    }
  }
}
</style>
