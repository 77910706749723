const rights = {
  TaskCreate: "task_create",
  TaskEdit: "task_edit",
  TaskDelete: "task_delete",
  FieldsEdit: "fields_edit",
  FieldsView: "fields_view",
  IsAdmin: "is_admin",
  IsAuthorized: "is_authorized",
  LayoutEdit: "layout_edit",
};

export { rights };
