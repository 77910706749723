import { RAIDA_API } from "../../env/.env";

const RAIDA_URL = RAIDA_API + "/api";

export default {
  login() {
    return {
      path: `${RAIDA_URL}/users/token`,
      method: "post",
      headers: {},
    };
  },

  registration() {
    return {
      path: `${RAIDA_URL}/users`,
      method: "post",
      headers: {},
    };
  },

  getNodeByDomain(domain) {
    return {
      path: `${RAIDA_URL}/nodes/domain/${domain}`,
      method: "get",
    };
  },

  getNodes(userId) {
    return {
      path: `${RAIDA_URL}/nodes?user_id=${userId}`,
      method: "get",
    };
  },

  editNode(nodeId) {
    return {
      path: `${RAIDA_URL}/nodes/${nodeId}`,
      method: "patch",
    };
  },

  bindUserToNode() {
    return {
      path: `${RAIDA_URL}/nodes/bind-user`,
      method: "post",
    };
  },

  /////////////

  getUsers(nodeId) {
    return {
      path: `${RAIDA_URL}/users/${nodeId}`,
      method: "get",
    };
  },

  getRights(nodeId) {
    return {
      path: `${RAIDA_URL}/rights/all-users/${nodeId}`,
      method: "get",
    };
  },

  deleteUser(userId) {
    return {
      path: `${RAIDA_URL}/users/${userId}`,
      method: "delete",
    };
  },

  editUser(userId) {
    return {
      path: `${RAIDA_URL}/users/${userId}`,
      method: "patch",
    };
  },

  createUser() {
    return {
      path: `${RAIDA_URL}/users`,
      method: "post",
    };
  },

  getTeams(nodeId) {
    return {
      path: `${RAIDA_URL}/teams/${nodeId}`,
      method: "get",
    };
  },

  createTeam(nodeId) {
    return {
      path: `${RAIDA_URL}/teams/${nodeId}`,
      method: "post",
    };
  },

  bindTeamToProcess(nodeId) {
    return {
      path: `${RAIDA_URL}/teams/bind-team/${nodeId}`,
      method: "post",
    };
  },

  editTeam({ nodeId, teamId }) {
    return {
      path: `${RAIDA_URL}/teams/${nodeId}/${teamId}`,
      method: "patch",
    };
  },

  deleteTeam({ nodeId, teamId }) {
    return {
      path: `${RAIDA_URL}/teams/${nodeId}/${teamId}`,
      method: "delete",
    };
  },

  editProcesses({ nodeId, processId }) {
    return {
      path: `${RAIDA_URL}/processes/${nodeId}/${processId}`,
      method: "patch",
    };
  },

  deleteProcesses({ nodeId, processId }) {
    return {
      path: `${RAIDA_URL}/processes/${nodeId}/${processId}`,
      method: "delete",
    };
  },

  bindFormToProcess(nodeId) {
    return {
      path: `${RAIDA_URL}/forms/bind-form/${nodeId}`,
      method: "post",
    };
  },

  getProcesses(nodeId) {
    return {
      path: `${RAIDA_URL}/processes/${nodeId}`,
      method: "get",
    };
  },

  createProcess(nodeId) {
    return {
      path: `${RAIDA_URL}/processes/${nodeId}`,
      method: "post",
    };
  },

  getStatuses(nodeId) {
    return {
      path: `${RAIDA_URL}/statuses/${nodeId}`,
      method: "get",
    };
  },

  editStatus({ nodeId, statusId }) {
    return {
      path: `${RAIDA_URL}/statuses/${nodeId}/${statusId}`,
      method: "patch",
    };
  },

  deleteStatus({ nodeId, statusId }) {
    return {
      path: `${RAIDA_URL}/statuses/${nodeId}/${statusId}`,
      method: "delete",
    };
  },

  createStatus(nodeId) {
    return {
      path: `${RAIDA_URL}/statuses/${nodeId}`,
      method: "post",
    };
  },

  getProgress({ nodeId, processId }) {
    return {
      path: `${RAIDA_URL}/progress/${nodeId}/${processId}`,
      method: "get",
    };
  },

  getProgresses(nodeId) {
    return {
      path: `${RAIDA_URL}/progress/${nodeId}`,
      method: "get",
    };
  },

  createProgress(nodeId) {
    return {
      path: `${RAIDA_URL}/progress/${nodeId}`,
      method: "post",
    };
  },

  editProgress({ nodeId, progressId }) {
    return {
      path: `${RAIDA_URL}/progress/${nodeId}/${progressId}`,
      method: "patch",
    };
  },

  deleteProgress({ nodeId, progressId }) {
    return {
      path: `${RAIDA_URL}/progress/${nodeId}/${progressId}`,
      method: "delete",
    };
  },

  bindStatusToProgress(nodeId) {
    return {
      path: `${RAIDA_URL}/statuses/bind-status/${nodeId}`,
      method: "post",
    };
  },

  getForms(nodeId) {
    return {
      path: `${RAIDA_URL}/forms/${nodeId}`,
      method: "get",
    };
  },

  editForm({ nodeId, formId }) {
    return {
      path: `${RAIDA_URL}/forms/${nodeId}/${formId}`,
      method: "patch",
    };
  },

  createForm(nodeId) {
    return {
      path: `${RAIDA_URL}/forms/${nodeId}`,
      method: "post",
    };
  },

  deleteForm({ nodeId, formId }) {
    return {
      path: `${RAIDA_URL}/forms/${nodeId}/${formId}`,
      method: "delete",
    };
  },

  getWidgets(nodeId) {
    return {
      path: `${RAIDA_URL}/widgets/${nodeId}`,
      method: "get",
    };
  },

  editWidget({ nodeId, widgetId }) {
    return {
      path: `${RAIDA_URL}/widgets/${nodeId}/${widgetId}`,
      method: "patch",
    };
  },

  createWidget(nodeId) {
    return {
      path: `${RAIDA_URL}/widgets/${nodeId}`,
      method: "post",
    };
  },

  deleteWidget({ nodeId, widgetId }) {
    return {
      path: `${RAIDA_URL}/widgets/${nodeId}/${widgetId}`,
      method: "delete",
    };
  },

  bindWidgetToBoard(nodeId) {
    return {
      path: `${RAIDA_URL}/widgets/bind-board/${nodeId}`,
      method: "post",
    };
  },

  getBoards(nodeId) {
    return {
      path: `${RAIDA_URL}/boards/${nodeId}`,
      method: "get",
    };
  },

  editBoard({ nodeId, boardId }) {
    return {
      path: `${RAIDA_URL}/boards/${nodeId}/${boardId}`,
      method: "patch",
    };
  },

  createBoard(nodeId) {
    return {
      path: `${RAIDA_URL}/boards/${nodeId}`,
      method: "post",
    };
  },

  deleteBoard({ nodeId, boardId }) {
    return {
      path: `${RAIDA_URL}/boards/${nodeId}/${boardId}`,
      method: "delete",
    };
  },

  getFields(nodeId) {
    return {
      path: `${RAIDA_URL}/fields/${nodeId}`,
      method: "get",
    };
  },

  createField(nodeId) {
    return {
      path: `${RAIDA_URL}/fields/${nodeId}`,
      method: "post",
    };
  },

  editField({ nodeId, fieldId }) {
    return {
      path: `${RAIDA_URL}/fields/${nodeId}/${fieldId}`,
      method: "patch",
    };
  },

  deleteField({ nodeId, fieldId }) {
    return {
      path: `${RAIDA_URL}/fields/${nodeId}/${fieldId}`,
      method: "delete",
    };
  },

  getTaskById({ nodeId, taskId }) {
    return {
      path: `${RAIDA_URL}/tasks/${nodeId}/${taskId}`,
      method: "get",
    };
  },

  getTaskByKey({ nodeId, taskKey }) {
    return {
      path: `${RAIDA_URL}/tasks/${nodeId}/${taskKey}/key`,
      method: "get",
    };
  },

  getTasks({ nodeId, processId }) {
    let path = `${RAIDA_URL}/tasks/${nodeId}`;
    if (processId) path += `?process_id=${processId}`;

    return {
      path,
      method: "get",
    };
  },

  getTasksByRQL(nodeId) {
    return {
      path: `${RAIDA_URL}/tasks/rql/${nodeId}`,
      method: "post",
    };
  },

  createTask({ nodeId, processId }) {
    return {
      path: `${RAIDA_URL}/tasks/${nodeId}/${processId}`,
      method: "post",
    };
  },

  editTask({ nodeId, taskId }) {
    return {
      path: `${RAIDA_URL}/tasks/${nodeId}/${taskId}`,
      method: "patch",
    };
  },

  editTasksByRQL(nodeId) {
    return {
      path: `${RAIDA_URL}/tasks/${nodeId}/rql`,
      method: "post",
    };
  },

  createAttachment({ nodeId, taskId, type }) {
    let path = `${RAIDA_URL}/attachments/${nodeId}/${taskId}`;
    if (type) path += `?type=${type}`;

    return {
      path,
      method: "post",
    };
  },

  deleteAttachment({ nodeId, attachmentId }) {
    return {
      path: `${RAIDA_URL}/attachments/${nodeId}/${attachmentId}`,
      method: "delete",
    };
  },

  getPluginStore() {
    return {
      path: `${RAIDA_URL}/plugins`,
      method: "get",
    };
  },

  getPluginConfigs(nodeId) {
    return {
      path: `${RAIDA_URL}/plugin-configs/${nodeId}`,
      method: "get",
    };
  },

  getPluginConfig({ nodeId, pluginConfigId }) {
    return {
      path: `${RAIDA_URL}/plugin-configs/${nodeId}/${pluginConfigId}`,
      method: "get",
    };
  },

  createPluginConfig({ nodeId, pluginId }) {
    return {
      path: `${RAIDA_URL}/plugin-configs/${nodeId}/${pluginId}`,
      method: "post",
    };
  },

  editPluginConfig({ nodeId, pluginConfigId }) {
    return {
      path: `${RAIDA_URL}/plugin-configs/${nodeId}/${pluginConfigId}`,
      method: "patch",
    };
  },

  deletePluginConfig({ nodeId, pluginConfigId }) {
    return {
      path: `${RAIDA_URL}/plugin-configs/${nodeId}/${pluginConfigId}`,
      method: "delete",
    };
  },

  getStorageToken(userId) {
    return {
      path: `${RAIDA_URL}/users/token/${userId}`,
      method: "get",
    };
  },
};
