const state = {
  window: {
    width: 0,
    height: 0,
  },
};

const actions = {
  init: ({ commit }) => {
    commit("setWindowSize");
    window.addEventListener("resize", () => {
      commit("setWindowSize");
    });
  },
};

const win = window;
const doc = document;
const docElem = doc.documentElement;
const body = doc.getElementsByTagName("body")[0];

const mutations = {
  setWindowSize: (state) => {
    const x = win.innerWidth || docElem.clientWidth || body.clientWidth;
    const y = win.innerHeight || docElem.clientHeight || body.clientHeight;
    state.window.width = x;
    state.window.height = y;
  },
};

const getters = {
  isMobile: (state) => state.width < 768,
  isTablet: (state) => state.width < 810,
  isDesktop: (state) => state.width >= 810,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
