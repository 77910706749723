<template>
  <v-textarea
    v-bind="$attrs"
    :value="value"
    no-resize
    :rows="rows"
    @input="$emit('input', $event)"
    @change="$emit('change', $event)"
    @blur="$emit('blue', $event)"
  ></v-textarea>
</template>

<script>
export default {
  props: {
    value: {
      default: "",
    },
    rows: {
      default: "3",
    },
  },
};
</script>
