export default [
  {
    path: "page-not-found",
    name: "PageNotFound",
    component: () => import("@/apps/errors/views/PageNotFound"),
    meta: { title: "Страница ошибки" },
  },
  {
    path: "node-not-found",
    name: "NodeNotFound",
    component: () => import("@/apps/errors/views/NodeNotFound"),
    meta: { title: "Страница ошибки" },
  },
  {
    path: "node-not-binded",
    name: "NodeIsNotBindedWithUser",
    component: () => import("@/apps/errors/views/NodeIsNotBindedWithUser"),
    meta: { title: "Страница ошибки" },
  },
];
