<template>
  <v-row>
    <v-col
      v-for="(fieldValue, fieldKey, i) in value"
      :key="fieldKey + i"
      cols="12"
      sm="12"
      :md="fieldSettings[fieldKey].grid || 12"
    >
      <AppFieldCreator
        v-bind="$attrs"
        :label="fieldSettings[fieldKey].title"
        :placeholder="fieldSettings[fieldKey].placeholder"
        :readonly="fieldSettings[fieldKey].readonly"
        :value="value[fieldKey]"
        :type="fieldSettings[fieldKey].type"
        :v="v[fieldKey]"
        :items="fieldSettings[fieldKey].options"
        :itemText="fieldSettings[fieldKey].optionText"
        :itemValue="fieldSettings[fieldKey].optionValue"
        hide-details="auto"
        dense
        @input="onInput($event, fieldKey)"
        @change="$emit('change', $event)"
        @blur="$emit('blur', $event)"
      />
    </v-col>
  </v-row>
</template>

<script>
import appJsonMixin from "@/components/fields/mixins/appJsonMixin";

export default {
  mixins: [appJsonMixin],
};
</script>
