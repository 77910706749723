<template>
  <v-file-input
    v-bind="$attrs"
    small-chips
    multiple
    @change="$emit('input', $event)"
  ></v-file-input>
</template>

<script>
export default {
  props: ["value", "type"],
};
</script>

<style scoped lang="scss"></style>
