<template>
  <div>
    <AppFileViewer
      v-bind="$attrs"
      :value="value"
      @input="$emit('input', $event)"
      @blur="$emit('blur', $event)"
      @onAttachmentAppend="$emit('onAttachmentAppend', $event)"
    />
    <AppFileLoader
      v-bind="$attrs"
      @input="$emit('input', $event)"
      @blur="$emit('blur', $event)"
    />
  </div>
</template>

<script>
// fix that component
export default {
  props: {
    value: {
      type: [String, Object, Array],
    },
  },
};
</script>

<style scoped lang="scss"></style>
