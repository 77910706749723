import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import ru from "vuetify/lib/locale/ru";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#343957",
        accent: "#6b51df",
        text: "#373737",
        red: "#D75E4E",
        yellow: "#FFE178",
        grey: "#DDDDDD",
        green: "#CAF373",
        bg: "#f8f9fe",
        adminBg: "#363636",
      },
      dark: {
        primary: "#343957",
        adminBg: "#363636",
      },
    },
  },
  lang: {
    locales: { ru },
    current: "ru",
  },
});
