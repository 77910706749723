import auth from "@/apps/auth/routes";
import boards from "@/apps/boards/routes";
import errors from "@/apps/errors/routes";
import home from "@/apps/home/routes";
// import nodes from "@/apps/nodes/routes";
import tasks from "@/apps/tasks/routes";
// admin
import adminBoards from "@/apps/admin/apps/adminBoards/routes";
import adminForms from "@/apps/admin/apps/adminForms/routes";
import adminPlugins from "@/apps/admin/apps/adminPlugins/routes";
import adminProcesses from "@/apps/admin/apps/adminProcesses/routes";
import adminStatuses from "@/apps/admin/apps/adminStatuses/routes";
import adminUI from "@/apps/admin/apps/adminUI/routes";
import adminUsers from "@/apps/admin/apps/adminUsers/routes";

import { adminGuard } from "./middleware/guards";

export default [
  // Авторизация
  {
    path: "/auth",
    component: () => import("@/components/layouts/layouts/PublicLayout"),
    children: [...auth],
  },
  // Список всех узлов пользователя
  {
    path: "/",
    component: () => import("@/components/layouts/layouts/HomeLayout"),
    redirect: { name: "home" },
  },
  // {
  //   path: "/",
  //   component: () => import("@/components/layouts/layouts/HomeLayout"),
  //   children: [...nodes],
  //   meta: { requiresAuth: true },
  // },
  // личный кабинет пользователя
  {
    path: "/lk",
    component: () => import("@/components/layouts/layouts/LkLayout"),
    children: [...home, ...tasks, ...boards],
    meta: { requiresAuth: true, requiresDomain: true },
  },
  {
    path: "/admin",
    component: () => import("@/components/layouts/layouts/AdminLayout"),
    children: [
      ...adminUsers,
      ...adminUI,
      ...adminStatuses,
      ...adminProcesses,
      ...adminForms,
      ...adminBoards,
      ...adminPlugins,
    ],
    meta: { requiresAuth: true, requiresDomain: true },
    beforeEnter: adminGuard,
  },
  // страницы ошибок
  {
    path: "/errors",
    component: () => import("@/components/layouts/layouts/PublicLayout"),
    children: [...errors],
  },
  {
    path: "*",
    redirect: { name: "PageNotFound" },
  },
];
