import api from "@/api";

const state = {
  rights: {},
};

const actions = {
  getRights: async ({ commit, rootState }) => {
    try {
      const { data } = await api.request({
        name: "getRights",
        pathParam: rootState["core$common"].node.id,
        payload: rootState["core$common"].users.map((user) => user.id),
      });
      commit("setRights", data.data);
    } catch (error) {
      console.error(error);
    }
  },
};

const mutations = {
  setRights: (state, rights) => (state.rights = rights),
};

const getters = {
  userRights: (state, getters, rootState) => {
    const userId = rootState["core$common"].user?.id;
    if (!userId) return [];

    return state.rights[userId] || [];
  },
  hasRight: (state, getters) => (right_code, attr) => {
    if (!getters.userRights.length) return false;

    const catchedRight = getters.userRights.find(
      (userRight) => userRight.right_code === right_code
    );

    if (!attr) return !!catchedRight;

    const catchedRightAttrs = catchedRight?.attributes;
    if (!catchedRightAttrs) return false;

    return catchedRightAttrs.includes(attr);
  },
  getRightAttrs: (state, getters) => (right_code) => {
    if (!getters.userRights.length) return null;

    const catchedRight = getters.userRights.find(
      (userRight) => userRight.right_code === right_code
    );

    if (!catchedRight || !catchedRight.attributes) return null;

    return catchedRight.attributes;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
