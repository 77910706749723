export default function (text, maxLength = 30) {
  const splited = text.split(".");

  if (!splited.length) return text;

  const fileName = splited[0];
  const fileSuffix = splited[1];

  if (fileName.length > maxLength) {
    const part1 = fileName.substring(0, maxLength - 5);
    const part2 = fileName.substring(fileName.length - 4);
    return part1 + "..." + part2 + "." + fileSuffix;
  }

  return text;
}
