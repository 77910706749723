export default [
  {
    path: "statuses",
    name: "adminStatuses",
    component: () =>
      import("@/apps/admin/apps/adminStatuses/views/AdminStatuses.vue"),
  },
  {
    path: "progress",
    name: "adminProgress",
    component: () =>
      import("@/apps/admin/apps/adminStatuses/views/AdminProgress.vue"),
  },
];
