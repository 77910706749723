import VueI18n from "@/locale/index";

export default (text, onlyWithPrefix = false, params) => {
  if (!text) return "";

  const isLocaleTextExists = VueI18n.te(text);
  if (!isLocaleTextExists) return text;

  if (!onlyWithPrefix) return VueI18n.t(text, params);

  return text.startsWith("$") ? VueI18n.t(text, params) : text;
};
